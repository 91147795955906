@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap");

body,
html {
  font-family: "Satoshi", sans-serif;
}

p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  font-family: "Satoshi", sans-serif;
}

body {
  background-color: #fafafa;
}

:root:has(
    :is(
        .modal-open,
        .modal:target,
        .modal-toggle:checked + .modal,
        .modal[open]
      )
  ) {
  scrollbar-gutter: auto !important;
}

.badge-font {
  font-family: "EB Garamond", serif;
}

.patternInscription {
  background-image: url("./assets/images/youngTeenagers.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.pattern {
  background-image: url("./assets/images/youngTeenagers.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.layout-child {
  width: calc(100vw - 290px);
}

@media screen and (max-width: 1024px) {
  .layout-child {
    width: 100vw;
  }
}

.main-menu .active button {
  font-weight: bolder;
}

.active button {
  color: black !important;
  font-weight: 800;
}

.custom-table {
  /* Apply border radius to the whole table */
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  overflow: hidden;
}

.custom-table thead th:first-child {
  border-top-left-radius: 10px !important;
}

/* Apply border radius to the last <th> in the <thead> */
.custom-table thead th:last-child {
  border-top-right-radius: 10px !important;
}

/* Autocomplete */
.MuiInputBase-root.MuiOutlinedInput-root {
  border-radius: 8px !important;
  font-family: "Satoshi", sans-serif;
  font-size: 14px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

#combo-box-demo-label {
  font-family: "Satoshi", sans-serif;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding-top: 2px !important;
}

#combo-box-demo.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 5px 4px 3px 8px !important;
  font-weight: 500 !important;
}

#combo-box-demo-dashboard.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 4px 4px 2px 8px !important;
  font-weight: 500 !important;
}

label.MuiFormLabel-root.MuiInputLabel-root {
  font-family: "Satoshi", sans-serif;
  font-size: 15px !important;
}

.MuiAutocomplete-root,
div,
label,
p,
span {
  font-family: "Satoshi", sans-serif;
}

.MuiAutocomplete-popper li {
  font-family: "Satoshi", sans-serif;
  font-size: 14px !important;
}

.MuiDrawer-paper.MuiDrawer-paperAnchorRight {
  background-color: white !important;
}

.statut {
  width: 120px !important;
}

.modal-transfert {
  overflow-y: unset !important;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  background: rgb(233, 233, 233) !important;
}

.vertical-timeline-element-content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  display: flex !important;
  justify-content: space-between !important;
  /* align-items: center !important; */
}

.vertical-timeline-element-content p {
  margin-bottom: 10px !important;
}

.MuiTabs-indicator {
  border: 2px solid #a70606;
}

.custom-tab-detail .MuiTabs-indicator {
  border: 2.5px solid #a70606;
}

.custom-tab-detail
  .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabScrollButton-horizontal.MuiTabs-scrollButtons.MuiTabs-scrollButtonsHideMobile
  svg {
  fill: white;
}

.MuiButtonBase-root.MuiTab-root {
  font-family: "Satoshi", sans-serif !important;
}

.custom-select {
  min-height: auto;
}

.css-8t49rw-MuiStepConnector-line {
  display: block;
  border-color: #bdbdbd;
  border-left-style: solid;
  border-left-width: 1px;
  min-height: 100% !important;
}
.react-datepicker__input-container {
  border: 1px solid rgba(31, 41, 55, 0.205);
  border-radius: 8px;
}

.react-datepicker__input-container input {
  border-radius: 8px;
  padding: 3px 8px;
}

@media screen and (max-width: 545px) {
  .update-catechumene > .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper {
    width: 100%;
  }
}

.divider:before, .divider:after {
  height: 0.2rem;
  background-color: #a70606;
}

#pdf {
  page-break-inside: avoid;
}

.card-box {
  /* width: 300px; */
  /* margin: 2mm 0; */
  page-break-inside: avoid;
  border-radius: 0px !important;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.card-box .backgroung-cover {
  background-image: url("https://res.cloudinary.com/dams9ix/image/upload/v1729777118/5868ae7d-4639-4e14-b7d4-3e14120c8b9c.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.15;
}

.multi-select .dropdown-content {
  font-weight: 500;
}

@page {
  size: A4 portrait;
  margin: 8mm;
  background-color: #f3f4f6 !important;
}

@media print {
  body {
    /* width: 210mm;
    height: 297mm; */
    width: 297mm;
    height: 210mm;
    margin: 0;
    padding: 0;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  .card-container {
    /* display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px; */
    width: 100%;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  @page {
    size: A4 landscape;
  }
}

/* @media print {
  @page {
    size: A4 landscape;
    margin: 5mm;
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    page-break-inside: avoid;
  }

  .card-box {
    width: 350px;
    margin: 2mm 0;
    page-break-inside: avoid;
    border-radius: 0px !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  #pdf {
    page-break-inside: avoid;
  }

  body {
    margin: 0;
    padding: 0;
  }

  .no-print {
    display: none;
  }
} */
